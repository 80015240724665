<template>
  <div id="d-container" class="d-container">
    <canvas
      id="drawing-area"
      class="drawing-area"
      height="500"
      width="500"
      @mousedown.prevent="handleWritingStart($event)"
      @mousemove.prevent="handleWritingInProgress($event)"
      @mouseup.prevent="handleDrawingEnd($event)"
      @mouseout.prevent="handleDrawingEnd($event)"
      @touchstart.prevent="handleWritingStart($event)"
      @touchmove.prevent="handleWritingInProgress($event)"
      @touchend.prevent="handleDrawingEnd($event)"
    ></canvas>
    <v-btn class="ma-2 pa-2" @click="handleClearButtonClick">Wissen</v-btn>
  </div>
</template>
<script>
export default {
  name: "drawingCanvas",
  data() {
    return {
      canvas: null,
      canvasContext: null,
      clearButton: null,
      state: {
      mousedown: false
    },
      lineWidth: 5,
      halfLineWidth: null,
      fillStyle: "#333",
      strokeStyle: "#333",
      shadowColor: "#333",
      shadowBlur: null,
      mousePos: { x: "", y: "" }
    }
  },
  methods: {
    handleWritingStart(event) {
      const mousePos = this.getMousePositionOnCanvas(event)
      this.canvasContext.beginPath()
      this.canvasContext.moveTo(mousePos.x, mousePos.y)
      this.canvasContext.lineWidth = this.lineWidth
      this.canvasContext.strokeStyle = this.strokeStyle
      this.canvasContext.shadowColor = null
      this.canvasContext.shadowBlur = null
      this.canvasContext.fill()
      this.state.mousedown = true
      this.$emit("sign-canvas",true)
    },
    handleWritingInProgress(event) {
      if (this.state.mousedown) {
        const mousePos = this.getMousePositionOnCanvas(event)
        this.canvasContext.lineTo(mousePos.x, mousePos.y)
        this.canvasContext.stroke()
      }
    },
    handleDrawingEnd() {
      if (this.state.mousedown) {
        this.canvasContext.shadowColor = this.shadowColor
        this.canvasContext.shadowBlur = this.shadowBlur
        this.canvasContext.stroke()
      }
      this.state.mousedown = false      
    },
    handleClearButtonClick() {
      this.clearCanvas()
    },
    getMousePositionOnCanvas(event) {
      var rect = document.getElementById("drawing-area").getBoundingClientRect()
      const clientX = event.clientX - rect.left || event.touches[0].clientX - rect.left
      const clientY = event.clientY - rect.top || event.touches[0].clientY - rect.top
      this.mousePos = { x: clientX, y: clientY }
      return { x: clientX, y: clientY }
    },
    clearCanvas() {
      this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.$emit("sign-canvas",false)
    }
  },
  mounted() {
    this.canvas = document.getElementById("drawing-area")
    this.canvasContext = this.canvas.getContext("2d")
    this.clearButton = document.getElementById("clear-button")
    this.halfLineWidth = this.lineWidth / 2
    this.shadowBlur = this.lineWidth / 4
  }
}
</script>
<style scoped>
.d-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.drawing-area {
  box-shadow: 0 0 6px 0 #999;
}
.clear-button {
  margin: 2em;
  font-size: 16px;
}
</style>