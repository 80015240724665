<template>
  <v-row>
    <v-col class="d-flex justify-center">
      <v-card width="800">
        <v-img
          height="250"
          src="https://image.freepik.com/free-photo/assortment-different-snacks-top-view-with-copyspace_144627-22885.jpg"
        ></v-img>
        <v-stepper v-model="step" non-linea>
          <v-stepper-header>
            <v-stepper-step step="1" :complete="step > 1 || quote.status == 2">
              Offerte accepteren
            </v-stepper-step>
            <v-stepper-step step="2" :complete="step > 2 || quote.status == 2">
              Controle allergieën
            </v-stepper-step>
            <v-stepper-step step="3" :complete="quote.status == 2">
              Controle gegevens
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card-title class="d-flex justify-center">Offerte</v-card-title>
              <div class="d-flex justify-center mb-10">
                <v-list width="500px">
                  <v-list-item>
                    <v-list-item-content>Naam:</v-list-item-content>
                    <v-list-item-content>{{ quote.name }}</v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Offertenummer: </v-list-item-content>
                    <v-list-item-content>{{
                      quote.quoteNumber
                    }}</v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Datum:</v-list-item-content>
                    <v-list-item-content>{{
                      toDateString(quote.date)
                    }}</v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Leverdatum:</v-list-item-content>
                    <v-list-item-content>{{
                      toDateString(quote.deliveryDate)
                    }}</v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Levertijd:</v-list-item-content>
                    <v-list-item-content>{{
                      quote.deliveryTime
                    }}</v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Leveradres:</v-list-item-content>
                    <v-list-item-content>{{
                      quote.client.shippingAddress
                    }}</v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Totaal incl. BTW:</v-list-item-content>
                    <v-list-item-content>{{
                      toCurrency(quote.debit)
                    }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
              <v-data-table
                dense
                :headers="itemHeaders"
                class="elevation-1 mb-10"
                locale="nl"
                :items="quote.items"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:[`item.description`]="{ item }">
                  <span v-html="item.description"></span>
                </template>
                <template v-slot:[`item.price`]="{ item }">
                  {{ toCurrency(item.price) }}
                </template>
                <template v-slot:[`item.VAT`]="{ item }">
                  {{ item.VAT }}%
                </template>
                <template v-slot:[`item.netSubtotal`]="{ item }">
                  {{ toCurrency(item.netSubtotal) }}
                </template>
                <template v-slot:[`item.grossSubtotal`]="{ item }">
                  {{ toCurrency(item.grossSubtotal) }}
                </template>
              </v-data-table>
              <v-card-subtitle v-if="quote.status!=2" class="d-flex justify-center"
                >Handtekening:</v-card-subtitle
              >
              <drawing-canvas
              v-if="quote.status!=2"
                ref="drawingCanvas"
                @sign-canvas="signCanvas"
              ></drawing-canvas>
              <div v-if="quote.status!=2" class="d-flex justify-center">
                <v-btn                  
                  :disabled="!canvasSigned"
                  @click="acceptQuote"
                  color="primary"
                  >Accepteer</v-btn
                >
              </div>
              <h2
                class="d-flex justify-center"
                v-if="quote._id && quote.status == 2"
              >
                Dank u wel, deze offerte is geaccepteerd
              </h2>
              <p v-else class="terms text-subtitle-1">
                Op deze offerte zijn de
                <a href="https://www.happiedelivery.nl/algemene-voorwaarden/"
                  >algemene voorwaarden</a
                >
                van Happie Delivery Catering van toepassing.<br />Door deze
                offerte te ondertekenen verklaart u de
                <a href="https://www.happiedelivery.nl/algemene-voorwaarden/"
                  >algemene voorwaarden</a
                >
                te hebben gelezen en akkoord te gaan met de
                <a href="https://www.happiedelivery.nl/algemene-voorwaarden/"
                  >algemene voorwaarden</a
                >
                van Happie Delivery Catering.
              </p>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card-title class="d-flex justify-center"
                >Kloppen deze allergieën?</v-card-title
              >
              <v-card-subtitle class="d-flex justify-center"
                >Vul aan indien nodig.</v-card-subtitle
              >
              <div class="d-flex justify-center mb-10 flex-column">
                <v-textarea
                  label="Allergieën"
                  v-model="quote.allergies"
                ></v-textarea>
              </div>
              <div class="d-flex justify-center">
                <v-btn color="primary" @click="step = 3">Bevestig</v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-card-title class="d-flex justify-center"
                >Kloppen deze gegevens?</v-card-title
              >
              <div class="d-flex justify-center mb-10 flex-column">
                 <v-radio-group row v-model="quote.client.type">
                   <v-radio label="Particulier" value="consumer"></v-radio>
                   <v-radio label="Bedrijf" value="company"></v-radio>
                 </v-radio-group>
                <legend>Contactgegevens</legend>
                <v-text-field
                  v-if="quote.client.type == 'company'"
                  label="Bedrijfsnaam"
                  v-model="quote.client.name"
                ></v-text-field>
                <v-text-field
                  v-if="quote.client.type == 'company'"
                  label="Contactpersoon`"
                  v-model="quote.client.contact"
                ></v-text-field>
                <v-text-field
                  v-if="quote.client.type == 'consumer'"
                  label="Naam"
                  v-model="quote.client.name"
                ></v-text-field>
                <v-text-field
                  label="Telefoonnummer"
                  v-model="quote.client.phone"
                ></v-text-field>
                <v-text-field
                  label="E-mailadres"
                  v-model="quote.client.email"
                ></v-text-field>
                <br />
                <legend>Leveradres</legend>
                <v-text-field
                  label="Adres"
                  v-model="quote.client.address"
                ></v-text-field>
                <v-text-field
                  label="Postcode"
                  v-model="quote.client.postalcode"
                ></v-text-field>
                <v-text-field
                  label="Plaats"
                  v-model="quote.client.city"
                ></v-text-field>
                <v-text-field
                  label="Land"
                  v-model="quote.client.country"
                ></v-text-field>
                <v-checkbox
                  label="Factuuradres is hetzelfde als leveradres"
                  v-model="billingAddressSameAsDeliveryAddress"
                ></v-checkbox>
                <template v-if="!billingAddressSameAsDeliveryAddress">
                  <legend>Factuuradres</legend>
                  <v-text-field
                    label="Adres"
                    v-model="quote.client.billingAddress"
                    v-if="!billingAddressSameAsDeliveryAddress"
                  ></v-text-field>
                  <v-text-field
                    label="Postcode"
                    v-model="quote.client.billingPostalcode"
                    v-if="!billingAddressSameAsDeliveryAddress"
                  ></v-text-field>
                  <v-text-field
                    label="Plaats"
                    v-model="quote.client.billingCity"
                    v-if="!billingAddressSameAsDeliveryAddress"
                  ></v-text-field>
                  <v-text-field
                    label="land"
                    v-model="quote.client.billingCountry"
                    v-if="!billingAddressSameAsDeliveryAddress"
                  ></v-text-field>
                </template>
                <div class="d-flex justify-center">
                  <v-btn color="primary" @click="updateQuote">Bevestig</v-btn>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { config } from "../config/config"
import { toCurrency, toDateString } from "../js/functions"
import drawingCanvas from "@/components/drawingCanvas.vue"
export default {
  name: "viewQuote",
  data() {
    return {
      quote: {
        name: "",
        quoteNumber: "",
        date: "",
        deliveryDate: "",
        debit: 0,
        items: [],
        signature: "",
        client: {
          shippingAddress: "",
          type: "",
          country: "NL",
          billingCountry: "NL"
        }
      },
      itemHeaders: [
        { text: "Naam", value: "name", sortable: false },
        { text: "Omschrijving", value: "description", sortable: false },
        { text: "Aantal", value: "amount", sortable: false },
        { text: "Eenheid", value: "unit", sortable: false },
        { text: "Prijs", value: "price", sortable: false },
        { text: "BTW", value: "VAT", sortable: false },
        { text: "Subtotaal", value: "netSubtotal", sortable: false },
        { text: "Totaal incl.", value: "grossSubtotal", sortable: false }
      ],
      canvasSigned: false,
      step: "1",
      billingAddressSameAsDeliveryAddress: true
    }
  },
  methods: {
    toDateString(n) {
      if (n) return toDateString(n)
      return
    },
    toCurrency(n) {
      return toCurrency(n)
    },
    async viewQuote(_id) {
      console.log(_id)
      let data = JSON.stringify({ _id: _id })
      let res = await fetch(`${config.api.host}/viewQuote/${data}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
      let response = await res.json()
      this.quote = response
    },
    async acceptQuote() {
      this.quote.status = 2
      let image = this.$refs.drawingCanvas.canvas
      this.quote.signature = image.toDataURL()
      let res = await fetch(`${config.api.host}/acceptQuote`, {
        body: JSON.stringify(this.quote),
        method: "POST",
        headers: { "Content-Type": "application/json" }
      })
      let response = await res.json()
      console.log(response)
      this.step = "2"
    },
    async updateQuote() {
      if(this.billingAddressSameAsDeliveryAddress) {
        this.quote.client.address = this.quote.client.shippingAddress
        this.quote.client.postalcode = this.quote.client.shippingPostalcode
        this.quote.client.city = this.quote.client.shippingCity
        this.quote.client.country = this.quote.client.shippingCountry
        this.quote.client.billingAddress = this.quote.client.address
        this.quote.client.billingPostalcode = this.quote.client.postalcode
        this.quote.client.billingCity = this.quote.client.city
        this.quote.client.billingCountry = this.quote.client.country        
      }
      let res = await fetch(`${config.api.host}/updateQuote`, {
        body: JSON.stringify(this.quote),
        method: "POST",
        headers: { "Content-Type": "application/json" }
      })
      let response = await res.json()
      console.log(response)
      this.step = 1
    },
    signCanvas(val) {
      this.canvasSigned = val
    }
  },
  components: {
    drawingCanvas
  },
  mounted() {
    this.viewQuote(this.$route.params.id)
  }
}
</script>
<style scoped>
.terms {
  text-align: center;
}
</style>